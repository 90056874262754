import { parseContent } from "../../helpers/parseContent";

import missionImage from "../../assets/images/AboutUs/about_us-1.webp";
import visionImage from "../../assets/images/AboutUs/about_us-2.webp";
import valuesImage from "../../assets/images/AboutUs/about_us-3.webp";
import { HighlightedTitle } from "../ui/HighlightedTitle";
import Image from "../ui/Image";

function OurValues() {
  const OurValuesVariables = {
    valueItems: [
      {
        title: "Misión",
        highlightedText: "Misión",
        content: [
          {
            p: "Ofrecer la <b>mejor experiencia</b> y selección de productos para alquilar, tratando a nuestros <b>proveedores como socios</b>. Todo ello con nuestro ejemplo de economía circular.",
          },
          {
            p: "Nuestro compromiso es brindar un <b>servicio eficiente y de confianza</b>, donde nuestros clientes puedan encontrar <b>todo lo que necesitan en un sólo lugar</b>. Trabajamos con empresas de alquiler para asegurar una <b>alta calidad</b> y satisfacer las necesidades de nuestros usuarios.",
          },
          {
            p: "En resumen, estamos aquí para simplificar tu vida. Queremos que puedas acceder a una <b>amplia gama de productos de alquiler de manera fácil</b>. Confía en nosotros para encontrar lo que necesitas y disfruta de la comodidad de <b>alquilar en línea</b>. ¡Estamos preparados para <b>ayudarte</b>  en todo momento!",
          },
        ],
        image: missionImage,
        alt: "La mejor experiencia en productos de maquinaria y herramientas para alquilar",
        reverse: false,
      },
      {
        title: "Visión",
        highlightedText: "Visión",
        content: [
          {
            p: "Ser la <b>plataforma de alquiler online</b> donde los usuarios puedan encontrar <b>cualquier producto</b> que necesiten.",
          },
          {
            p: "Nuestra visión es crear un mundo en el que el <b>consumo responsable</b> sea la norma. Queremos que las personas se den cuenta de que <b>no es necesario poseer</b> todos los productos que utilizan en su vida diaria.",
          },
          {
            p: "Creemos en un mundo en el que el <b>consumo responsable</b> y el cuidado del <b>medio ambiente</b> van de la mano. Al alquilar en lugar de comprar estás apostando por un <b>futuro más sostenible</b>.",
          },
        ],
        image: visionImage,
        alt: "La mejor experiencia en productos de maquinaria y herramientas para alquilar",
        reverse: true,
      },
      {
        title: "Valores",
        highlightedText: "Valores",
        content: [
          {
            p: "<b>Compromiso</b> con el cliente",
          },
          {
            p: "<b>Excelencia, calidad e innovación</b> en todos nuestros servicios",
          },
          {
            p: "Seguridad y <b>confianza</b>.",
          },
          {
            p: "<b>Responsabilidad y compromiso</b> con el medio ambiente: por un futuro mejor",
          },
        ],
        image: valuesImage,
        alt: "La mejor experiencia en productos de maquinaria y herramientas para alquilar",
        reverse: false,
      },
    ],
  };

  const { valueItems } = OurValuesVariables;

  return (
    <section className="bg-white text-start py-14">
      <div className="container m-auto px-4 lg:px-20 space-y-14 md:space-y-24">
        {valueItems.map((valueItem, index) => (
          <ValueItem key={index} valueItem={valueItem} index={index} />
        ))}
      </div>
    </section>
  );
}

function ValueItem({ valueItem }) {
  const { title, highlightedText, image, alt, content, reverse } = valueItem;

  return (
    <div className={`w-full md:flex items-center gap-12`}>
      <div
        className={`h-full w-full md:w-7/12 ${
          reverse ? "order-2 md:order-1" : ""
        }`}
      >
        <h2 className="pt-9 pb-4 headline text-black">
          <HighlightedTitle
            title={title}
            highlight={highlightedText}
            highlightClass="text-primary lowercase"
          />
        </h2>
        {content.map((item, index) => (
          <p key={index} className="pb-2 body text-dark leading-5">
            {parseContent(item.p)}
          </p>
        ))}
      </div>
      <div
        className={`h-full w-full md:w-5/12 ${
          reverse ? "order-last md:order-first" : ""
        }`}
      >
        <Image src={image} alt={alt} customClass="rounded-3xl object-contain" />
      </div>
    </div>
  );
}

export default OurValues;
