import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
  const handleAccept = () => {
    window.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });
  };

  const handleDecline = () => {
    window.gtag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
  };

  return (
    <CookieConsent
      disableButtonStyles={true}
      onAccept={handleAccept}
      onDecline={handleDecline}
      buttonText="Aceptar"
      declineButtonText="Rechazar"
      enableDeclineButton
      buttonWrapperClasses="flex justify-center w-full lg:w-44 mb-4"
      buttonClasses="ButtonCookies w-full lg:w-fit mr-5 px-2 py-2 text-white transition-all duration-150 ease-in-out rounded-full bg-primary hover:bg-opacity-90"
      declineButtonClasses="ButtonCookies w-full lg:w-fit mr-4 px-2 py-2 text-white transition-all duration-150 ease-in-out rounded-full bg-red-500 hover:bg-opacity-90"
    >
      Utilizamos cookies y tecnologías similares para ofrecer la mejor experiencia en nuestro sitio web. Consulta nuestra Política de privacidad para obtener más información.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
